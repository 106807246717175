import {createStore} from 'vuex'
import VK from '@/store/modules/vk'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {VK}
})
