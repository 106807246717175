export default {
  // 'auth/admin-restricted-operation': 'Доступно только администраторам',
  // 'auth/wrong-password': 'Неправильный пароль',
  // 'auth/user-not-found': 'Пользователь с такой почтой не найден',
  // 'auth/invalid-email': 'Недопустимый формат Email',
  // 'auth/user-disabled': 'Ваш аккаунт заблокирован'
  // '': '',
  // '': '',
  // '': '',
  // '': '',
  // '': ''
}
