<template>
  <router-view />
</template>

<script>
// import VK from '@vkontakte/vk-bridge'

export default {
  mounted() {
    // Если мобильное устройство, то добавляется класс mobile
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.getElementById('app').classList.add('mobile')
    }
    // Обязательный запуск события, без него, API не будет работать
    // VK.send('VKWebAppInit', {})
    //   .then(data => {
    //     if (data.result) {
    //       console.log('Приложение инициализировано')
    //     } else {
    //       console.log('Ошибка инициализации приложения')
    //     }
    //   })
    //   .catch(error => {
    //     // Ошибка
    //     console.log('Ошибка VKWebAppInit', error)
    //   })

    // // Меняется тема приложения
    // VK.subscribe(e => {
    //   // console.log('Событие subscribe', e)
    //   if (e.detail.type === 'VKWebAppUpdateConfig') {
    //     document.querySelector('body').className = e.detail.data.scheme
    //   }
    // })
  },
  methods: {}
}
</script>

<style>
@import 'assets/style.css';
</style>
