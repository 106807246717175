import VK from '@vkontakte/vk-bridge'
import Toast from '@/utils/toast'

export default {
  state: {
    access_token: ''
  },
  actions: {
    vkInit({commit}) {
      VK.send('VKWebAppInit', {})
        .then(data => {
          // console.log('VKWebAppInit', data)
          if (data.result) {
            Toast.mes('Приложение инициализировано')

            VK.send('VKWebAppGetAuthToken', {
              app_id: 51655315,
              scope: 'friends,status'
            })
              .then(data => {
                // console.log('Ответ', data)
                if (data.access_token) {
                  Toast.mes('Токен получен')
                  commit('setAToken', data.access_token)
                } else {
                  Toast.err('Токен не получен')
                }
              })
              .catch(error => {
                Toast.err(error)
                console.log('Ошибка catch', error)
              })
          } else {
            Toast.err('Ошибка инициализации приложения')
          }
        })
        .catch(error => {
          Toast.err(error)
          console.log('Ошибка catch', error)
        })
    }
  },
  mutations: {
    setAToken(state, token) {
      state.access_tokens = token
    }
  },
  getters: {
    getAToken: state => state.access_tokens
  }
}
