import messages from '@/utils/messages'

export default {
  mes(text, succes = true) {
    // M.toast({html: messages[text] || text, classes: succes ? 'teal lighten-2 rounded' : 'rounded'})
    console.log(messages[text] || text)
  },
  err(text) {
    // M.toast({html: '[Ошибка]: ' + html, classes: 'red lighten-2'})
    // M.toast({ html: messages[text] || text, classes: 'red lighten-2 rounded', displayLength: 5000 })
    console.error(messages[text] || text)
  }
}
